<template>
	<div>
		<template v-if="description.length > 0">
			<div class="auction-table__description">
				<span v-if="isHtml" class="shortText mr-5">
						<span v-html="truncateText(description, 50)"></span> <span v-if="!readMore" class="mr-10">...</span>
				</span>
				<span v-else class="shortText mr-5">
						{{truncateText(description, 50) }} <span v-if="!readMore" class="mr-10">...</span>
				</span>
				<button @click="readMore =! readMore"
						class="Btn mt-5">
					<span v-if="!readMore">{{ $t('showMore') }}
					<img src="../../assets/icons/angle-down.svg" alt=""></span>
					<span v-else>{{ $t('showLess') }}
						<img src="../../assets/icons/angle-down.svg"
							 style="transform: rotate(180deg); margin-top: -10px"
							 alt="">
					</span>
				</button>
			</div>
		</template>

	</div>
</template>

<script>
	import AppModal from "./AppModal";
	import AppButton from "./AppButton";

	export default {
		name: "AppTextDescription",
		components: {AppButton, AppModal},
		props: {
			description: {
				type: String,
				default: ''
			},
			isHtml: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				readMore: false
			}
		},
		methods: {
			truncateText(text, num) {
				const cutText = text.split("").slice(0, num).join("");
				return this.readMore ? text : cutText;
			}
		}
	}
</script>

<style lang="scss" scoped>

	.Btn {
		padding: 5px;
		color: #9bb5cc;
		border-radius: 5px;
	}

	.shortText {

	}

</style>