<template>
  <component :is="horizontal ? 'span' : 'div'"
             class='custom-control custom-radio my_custom-radio'
             :class="[{'d-inline-block mr-15': horizontal},
             ]">
    <input
            class='custom-control-input'
            :id='uuid'
            type='radio'
            :checked='value === radioValue'
            :value='radioValue'
            @change="$emit('input', radioValue)"
            v-bind='$attrs'
    >
    <label :for='uuid'
           v-if='label'
           class='custom-control-label'
           :class='{radio_button__white: white}'>
      {{ label }}
    </label>
  </component>
</template>
<script>
  import uniqueId from '../../utils/uniqueId'

  export default {
    props: {
      value: {
        type: [String, Number, Boolean],
        default: ''
      },

      horizontal: {
        type: Boolean,
        default: false
      },

      white: {
        type: Boolean,
        default: false
      },

      radioValue: {
        type: [String, Number, Boolean],
        required: true
      },

      label: {
        type: String,
        default: ''
      },

      classes: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        uuid: uniqueId().getId()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .custom-control-input {
    margin-right: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      z-index: 1;
      display: inline-block;
      visibility: visible;
      border-radius: 50%;
      background-color: #E5EFF5;
    }

    &:checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      z-index: 1;
      display: inline-block;
      visibility: visible;
      border-radius: 50%;
      background-color: var(--color-main);
    }

    &:checked::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: white;
      display: inline-block;
      visibility: visible;
    }

  }

  .custom-control {
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      cursor: pointer;
    }

    &-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
    }
  }


</style>
