<template>
	<div>
		<AppRadio
			v-for='(option, i) in options'
			:key='i'
			:value='value'
			:radio-value='option.radioValue'
			:label='option.label'
			:name='name'
			@input="$emit('input', $event)"
			:horizontal='horizontal'
			:white='white'
		/>
	</div>
</template>

<script>
import AppRadio from '../../components/shared-components/AppRadio'

export default {
	components: {AppRadio},
	props: {
		value: {
			type: [String, Number, Boolean]
		},

		horizontal: {
			type: Boolean,
			default: false
		},

		white: {
			type: Boolean,
			default: false
		},

		options: {
			type: Array,
			required: true
		},

		name: {
			type: String,
			required: false,
			default: ''
		}
	}
}
</script>
