<template>
	<AppModal max-width="760" v-if="dialog" v-model="dialog">
		<div class="modal__holder">
			<div class="modal-header mb-30">
				<AppText size="18" line-height="23" weight="700"
				>Подать заявку
				</AppText>
			</div>
			<div class="modal-content">
				<div class="apply__item mb-20">
					<AppRadioGroup
						@input="onChangeGlobalNds"
						name="NDS"
						:options="vat"
						v-model="isNds"
						horizontal
						class="mr-30 mb-20"
					/>

					<hr class="hr"/>

					<ul class="mt-30 mb-30" style="font-size: 14px; font-weight: 500">
						<li class="mb-10">
							Цена товара/работы/услуги:
							<b style="font-size: 16px; padding-left: 5px"
							>{{ allSum | toFix | moneyFormat }} </b
							>
						</li>
						<li class="mb-10">
							Цена за НДС ( {{ ndsPercent }}%):
							<b style="font-size: 16px; padding-left: 5px"
							>{{ ndsSum | toFix | moneyFormat }} </b
							>
						</li>
						<li class="mb-10">
							Общая сумма:
							<b
								style="
                  font-size: 18px;
                  color: var(--color-success);
                  padding-left: 5px;
                "
							>{{ nextPrice | toFix | moneyFormat }} </b
							>
						</li>
					</ul>
				</div>

				<hr class="hr"/>

				<div class="apply__item mt-20">
					<AppRadioGroup
						@input="onChangeGlobalCountry"
						:options="selectCountry"
						name="country"
						horizontal=""
						class="mr-30 mb-20"
					/>

					<div
						class="table-block apply-table"
						style="max-height: 450px; overflow-y: auto"
					>
						<table>
							<thead>
							<tr>
								<th>Наименование</th>
								<th width="200">НДС</th>
								<th width="200">Производитель</th>
							</tr>
							</thead>
							<tbody>
							<tr :key="item.id" v-for="item in products">
								<td>{{ item.productName|translate }}</td>
								<td>
									<AppRadioGroup
										:options="vat"
										v-model="item.isNds"
										@input="changePrice(item)"
									/>
								</td>
								<td>
									<AppRadioGroup
										:options="selectCountry"
										v-model="item.isLocalManufacturer"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="modal-footer d-flex justify-content-right mt-30">
				<div style="margin-right: 10px">
					<AppButton
						@click="dialog = false"
						sides="20"
						font-size="14"
						height="40"
						theme="secondary"
					>
						Отмена
					</AppButton>
				</div>
				<AppButton
					:loading="loading"
					@click="submit"
					sides="20"
					font-size="14"
					height="40"
					theme="main"
				>
					Подтвердить
				</AppButton>
			</div>
		</div>
	</AppModal>
</template>

<script>
import AppModal from "@/components/shared-components/AppModal";
import AppButton from "@/components/shared-components/AppButton";
import AppRadioGroup from "@/components/shared-components/AppRadioGroup";
import AppText from "@/components/shared-components/AppText";

export default {
	name: "AuctionRequestDialog",
	props: {
		lotId: {},
		nextPrice: {
			type: Number,
			default: 0,
		},
		lotSum: {
			type: Number,
			default: 0,
		},
	},
	components: {
		AppModal,
		AppButton,
		AppRadioGroup,
		AppText,
	},
	emits: ["saved"],
	data() {
		return {
			loading: false,
			isNds: false,
			dialog: false,
			ndsPercent: 0,
			products: [],
			selectCountry: [
				{
					label: "Узбекистан",
					radioValue: false,
				},
				// {
				// 	label: "Другой",
				// 	radioValue: true,
				// },
			],
			vat: [
				{
					label: "Учитывать НДС",
					radioValue: true,
				},
				{
					label: "Без учета НДС",
					radioValue: false,
				},
			],
			ndsSum: 0,
			allSum: 0
		};
	},
	methods: {
		changePrice(obj = null) {
			let part = this.nextPrice / this.lotSum;
			let ndsSum = 0;
			let allSum = 0;
			this.products.forEach(item => {
				let onePrice = item.quantity * item.price;
				onePrice = onePrice * part;
				if (item.isNds) {
					let oneNds = (onePrice * this.ndsPercent) / (100 + this.ndsPercent);
					ndsSum = ndsSum + oneNds;
					allSum = allSum + (onePrice - oneNds);
				} else {
					allSum = allSum + onePrice;
				}
			})
			this.ndsSum = ndsSum;
			this.allSum = allSum;
		},
		onChangeGlobalCountry(val) {
			this.products = this.products.map((item) => {
				item.isLocalManufacturer = val;
				return item;
			});
		},
		onChangeGlobalNds(val) {
			this.products = this.products.map((item) => {
				item.isNds = val;
				return item;
			});
			this.changePrice();
		},
		clear() {
			this.products = [];
		},
		async submit() {
			if (!this.loading) {
				this.loading = true;
				const products = this.products.map((item) => {
					return {
						productId: item.productId,
						isLocalManufacturer: item.isLocalManufacturer,
						isNds: !!item.isNds,
					};
				});
				try {
					const {
						data: {
							result: {success},
						},
					} = await this.$api.post("api-auction/Provider/ConfirmApplication", {
						lotId: this.lotId,
						isNds: this.isNds,
						listLocalManufacturer: products,
					});
					if (success) {
						this.$emit("saved");
						this.dialog = false;
					}
				} finally {
					this.loading = false;
				}
			}
		},
		async getNds() {
			try {
				const {
					data: {result},
				} = await this.$api.get("/api-auction/Provider/GetNds");
				this.ndsPercent = result.nds;
				this.changePrice();
			} catch (error) {
				this.$notification.error(error);
			}
		},
		open(products) {
			this.getNds();
			this.dialog = true;
			this.products = products.map((item) => {
				return {
					...item,
					productId: item.productId,
					isLocalManufacturer: false,
					isNds: false,
					productName: item.productName,
				};
			});
		},
	},
};
</script>