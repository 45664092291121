<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        :style="style"
      >
        <header class="modal-header" id="modalTitle" v-if="hasHeader">
          <slot name="header">
            {{ headerTitle }}
          </slot>
          <app-button
            v-if="hasHeaderClose"
            class="a-btn a-btn--th-accent"
            theme="white"
            @click="close"
            >x</app-button
          >
        </header>

        <section class="modal-body" id="modalDescription">
          <slot name="body"> This is the default body! </slot>
        </section>

        <footer class="modal-footer" v-if="hasFooter">
          <slot name="footer"> </slot>
          <div class="d-flex modal-btn-group">
            <app-button
            v-if="hasOkBtn"
              class="a-btn-a pa-10 a-btn-a--th-accent fw-500"
              @click="$emit('ok')"
            >
              {{ okBtnTitle }}
            </app-button>
            <app-button
              v-if="hasFooterClose"
              class="a-btn-a pa-10 ml-10 a-btn-a--th-accent fw-500 gray-light"
              theme="gray-light"
              @click="
                close();
                $emit('cancel');
              "
            >
              {{ cancelBtnTitle }}
            </app-button>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>
<!-- usage -->
<!-- <template v-slot:header>
    This is a new modal header.
  </template>

  <template v-slot:body>
    This is a new modal body.
  </template>

  <template v-slot:footer>
    This is a new modal footer.
  </template> -->
<script>
import AppButton from "@/components/shared-components/AppButton";

export default {
  name: "Modal",
  props: {
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: String,
      default: "Header",
    },
    cancelBtnTitle: {
      type: String,
      default: "Отмена",
    },
    okBtnTitle: {
      type: String,
      default: "Запросить",
    },
    hasHeaderClose: {
      type: Boolean,
      default: true,
    },
    hasFooterClose: {
      type: Boolean,
      default: true,
    },
    hasOkBtn: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: 513,
    },
    height: {
      type: [Number, String],
      default: 242,
    },
    radius: {
      type: [String, Number],
      default: 8,
    },
  },
  components: {
    AppButton,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    checkStyles(style) {
      return style === "initial" ||
        style === "inherit" ||
        style === "auto" ||
        style === "none" ||
        (typeof style === "string" && style.includes("%"))
        ? style
        : style + "px";
    },
  },
  computed: {
    style() {
      return {
        height: this.checkStyles(this.height),
        width: this.checkStyles(this.width),
        "border-radius": this.checkStyles(this.radius),
      };
    },
  },
};
</script>


<!--
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: auto;
  opacity: 1!important;
}

.modal-header,
.modal-footer {
  display: flex;
}

.modal-header {
  position: relative;
  color: #101312;
  justify-content: space-between;
}

.modal-footer {
  /* border-top: 1px solid #eeeeee; */
  flex-direction: column;
  padding-top: 30px;
}

.modal-body {
  position: relative;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #ffe600;
  background: transparent;
}

.btn-green {
  color: white;
  background: #ffe600;
  border: 1px solid #ffe600;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-btn-group .a-btn {
  height: 40px;
  border-radius: 8px;
}

.modal-btn-group {
  display: flex;
  justify-content: flex-end;
}

.gray-light {
  background-color: #ecf2f6 !important;
}

.gray-light:hover {
  color: #353c47;
}
</style>
-->
